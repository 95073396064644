import React from "react";
import { MagazinLogo, BackgroundMagazin, Skybedd } from "../../assets/images";
import { Outlet } from "react-router-dom";

const AuthNavigation = () => {
  return (
    <div className={"relative w-screen bg-primary h-screen"}>
      <div
        className={"absolute  z-20 w-screen h-screen bg-secondary opacity-40"}
      />

      <div className="flex absolute z-30 w-screen min-h-screen min-w-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto object-contain w-auto rounded-full"
            src={Skybedd}
            alt="Your Company"
            style={
              {
                // boxShadow: "0px 0px 20px 20px rgba(228, 174, 59,1)",
              }
            }
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-secondary">
            Hesabınıza giriş yapınız.
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div
            className="bg-[rgba(255,255,255,0.8)] py-8 px-4 shadow sm:rounded-lg  sm:px-10"
            style={{
              boxShadow: "0px 0px 20px 20px rgba(153, 153, 150, 0.6)",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthNavigation;
