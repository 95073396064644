import axios from "axios";
import authStore from "../stores/authStore";
import cartStore from "../stores/cartStore";

export const api = "https://api.selectiveyazilim.com/magazin-furniture/api";
// export const api = "http://localhost:3900/api";

const instance = axios.create({
  baseURL: api,
  timeout: 100000000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (value) => {
    if (value?.response?.status === 401) {
      authStore.signOut();
      cartStore.deleteCart();
      window.location.href = "/auth/login";
    } else if (value?.response?.status === 403) {
      cartStore.deleteCart();
      window.location.href = "/auth/login";
    } else {
      Promise.reject(value);
    }
  }
);

export default instance;
