import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { observer } from "mobx-react-lite";
import useStore from "./hooks/useStore";
import routes from "./routes";
import {PrivateRoute} from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = observer(() => {
    const { auth, layout } = useStore();

    return (
        <LoadingOverlay active={layout.loading} spinner text={"Yükleniyor..."}>
            <ToastContainer />
            <BrowserRouter>
                <Routes>
                    {routes.map((route, i) => {
                        return (
                            <Route
                                key={i}
                                path={route.path}
                                element={
                                    route.isPrivate ? (
                                        <PrivateRoute>
                                            <route.element routes={route.subRoutes} />
                                        </PrivateRoute>
                                    ) : (
                                        <route.element routes={route.subRoutes} />
                                    )
                                }
                            >
                                {route.subRoutes.map((sub, index) => {
                                     return (
                                        <Route
                                            key={index}
                                            path={sub.path}
                                            element={<sub.element />}
                                        />
                                    );
                                })}
                            </Route>
                        );
                    })}
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={auth.isLogged ? "/dashboard/products" : "/auth/login"}
                            />
                        }
                    />
                </Routes>
            </BrowserRouter>
        </LoadingOverlay>
    );
});

export default App;
